import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  LinearProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { WalletData } from "api/types";
import fetcher from "api/helpers/fetcher";
import { getWalletList } from "api/helpers/api-constants";
import { useReduxReducer } from "redux/store";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import SearchIcon from "@mui/icons-material/Search";
import currencyFormat from "utils/currencyFormat";
import moment from "moment";
import Swal from "sweetalert2";
import { title } from "process";
import { ToastAlert } from "components/ui/Alert/ToastAlert";
import ToastNotification from "components/ui/ToastNotification/ToastNotification";

const Wallet = () => {
  const { t } = useTranslation();
  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const { isAdmin, userData } = useReduxReducer((state) => state.general);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayStart, setDisplayStart] = useState(0);
  const [displayLength, setDisplayLength] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [agencyNumber, setAgencyNumber] = useState<string>("");
  const [error, setError] = useState<string>("");

  const fetchWalletData = async (agencyNum: string) => {
    if (agencyNum.length === 5) {
      try {
        setLoading(true);
        setError(""); 
        const response = await fetcher(
          getWalletList(displayStart.toString(), displayLength.toString(), "", agencyNum)
        );
        setWalletData(response);
        setTotalRecords(response.iTotalRecords);
      } catch (error) {
        console.error("Failed to fetch wallet data", error);
      } finally {
        setLoading(false);
      }
    } else {
      setError(t("react.validation.requiredfield")); 
    }
  };

  useEffect(() => {
    if (userData) {
      const agencyNum = userData.agencyNumber || "";
      setAgencyNumber(agencyNum);
      fetchWalletData(agencyNum);
    }
  }, [userData]);

  useEffect(() => {
    if (agencyNumber.length === 5) {
      fetchWalletData(agencyNumber);
    }
  }, [displayLength, displayStart]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setDisplayStart((page - 1) * displayLength);
  };

  const handleSearch = () => {
    if (agencyNumber.length === 5) {
      fetchWalletData(agencyNumber);
      setDisplayLength(10);
      setDisplayStart(0);
    } else {
      setError(t("react.validation.requiredfield")); 
    }
  };

  if (!userData || loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (<>{ isAdmin 
    ?<div className="container my-12 space-y-8 min-h-screen">
    {!walletData && <ToastNotification severity="error" message={t("app.general-error")}></ToastNotification>}
    {walletData && (
    <>
      <div className="flex justify-between items-center">
        <span className="text-lg font-semibold">
          <span className="items-center flex text-lg font-medium text-primary-6000">
            {walletData?.agencyName}
          </span>
          {t("wallet.balance")}:{" "}
          <span
            className="font-semibold text-lg"
            style={{
              color: walletData.walletBalance > 0 ? "green" : "red",
            }}
          >
            {currencyFormat(walletData?.walletBalance)}
          </span>
        </span>
        {isAdmin && (
          <div className="col-span-3 text-right ">
            <TextField
              className="pr-2"
              label={t("manage-users.agency-number")}
              size="small"
              value={agencyNumber}
              onChange={(e) => setAgencyNumber(e.target.value)}
              error={Boolean(error)} 
              helperText={error}
            />
            <ButtonPrimary onClick={handleSearch}>
              <SearchIcon className="w-5 h-5" />
              {t("app.search")}
            </ButtonPrimary>
          </div>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 600 }}>ID</TableCell>
              <TableCell style={{ fontWeight: 600 }}>
                {t("b2c-app.flights.date")}
              </TableCell>
              <TableCell style={{ fontWeight: 600 }}>
                {t("homepage.career.description")}
              </TableCell>
              <TableCell style={{ fontWeight: 600 }}>
                {t("wallet.transaction-amount")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {walletData.aaData.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell style={{ fontWeight: 400 }}>{row.id}</TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  {moment(row.txDate).format("DD.MM.YYYY")}
                </TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  {row.txDescription}
                </TableCell>
                <TableCell
                  style={{
                    color: row.txAmount > 0 ? "green" : "red",
                    fontWeight: 600,
                  }}
                >
                  {currencyFormat(row.txAmount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-end items-center space-x-4 gap-2">
        <Pagination
          count={Math.ceil(totalRecords / displayLength)}
          page={displayStart / displayLength + 1}
          onChange={handlePageChange}
          color="primary"
        />
        <Select
          value={displayLength}
          onChange={(e) => {
            setDisplayLength(e.target.value as number);
            setDisplayStart(0);
          }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </div>
    </>
  )}
</div>
        : <><ToastNotification severity="info" message="Kısa sürede yayında"/></>
  }
    </>
  );
};

export default Wallet;
