import { isB2B } from "./checkUserRole";

export const apiKey = "0ace09e1c1af91b556c9ff03f57de64f93a4";
export const BASENAME: string = isB2B() ? '/agency/create-new-reservation-react/' : '/'

export const b2cURL: string = 'https://b2c.atrtouristik.com'

const LOGOUT =  "/logout";
const CHECK_LOGGED_IN =  '/public/checkUserLoggedIn'
//redirect paths can get atr from getstaci util
const AGENCY_ADMIN_MANAGE_USER =  getHost() + "/agencyadmin/manageUsers?";  //mvc CmsPagesController.user  // path should create with baseURL
const AGENCY_INSURANCE = getHost() +  "/agency/insurance?"; //mvc CmsPagesController.insurance  // path should create with baseURL
const AGENCY_ADMIN_SETTINGS = getHost() +  "/agencyadmin/agencySettings?"; //mvc CmsPagesController.manageAgencies  // path should create with baseURL
const AGENCY_EXPI_TRANSACTION = getHost() + "/agency/expiTransactions?"; //mvc CmsPagesController.expiTransactions
const AGENCY_PRE_PAYMENT_WALLET =getHost() +  "/agency/prepaymentWallet?"; //mvc WalletController.prepaymentWallet
const JUPITER_VIEW_BOOKING = getHost() + "/agency/viewBooking/"; //mvc ReservationController.viewBooking // now it useless check and remove
const CHANGE_PNR_STATUS_URL = getHost() + "/intern/changePnrStatus?"
const AGENCY_REFRESH_PNR = "/agency/refreshPnr?"
const VIEWBOOKINGPDF_URL = getHost() + '/agency/viewBooking/' // should redirect to old page ???
const ISSUE_BOOKING_URL = getHost() + "/agency/issueBooking"
const ISSUE_BOOKING_WITH_STRIPE_URL = getHost() + "/agency/issueBookingWithStripe"
const GET_CANCELLATION_FEE = "/agency/hotels/getCancellationFee"
const ADD_SEGMENT = "/intern/addFlightSegment?"
//START API URLS
const CONFIG_URL = "/api/tenant/config"
const USER_DATA_URL =  "/api/app/userinfo"; //rest AppApiController.userinfo
const NOTIFICATION_URL =  "/api/agency/flightCase/byUser?"; //rest FlightCaseController.casesByUser
const RESOLVE_NOTIFICATION_CASE_URL =  "/api/agency/flightCase/resolve?"; //rest FlightCaseController.saveMessage
const NOTIFICATION_SAVE_MSG_URL =  "/api/agency/flightCase/saveMessage?"; //rest FlightCaseController.saveMessage
const USER_META_DATA_URL = "/api/agency/userMetaData?"; //rest CmsPagesController.userMetaData
const APPROACHINGFLIGHTS_URL = "/api/app/booking/approaching-flights" // to get aproachingFlights

//BOOKING DETAIL PAGE
const SAVE_REMARKS = '/api/agency/setRemarksForBooking?';
const SAVE_INTERNALREMARKS = '/api/intern/setInternalRemarksForBooking?'
const RESCANPNR =   "/api/agency/rescanPnr?"
const RESCANPNR_TAKERESERVATION = "/api/agency/rescanPnrTakeReservation?"
const VALIDATE_REFUND = "/api/agency/validateRefund"
const AGENCY_REFUND = "/api/agency/refund"
const ADD_CASE_LIST = "/api/agency/flightCase/addCase?"; //rest FlightCaseController.addCase
const FLIGHT_CASE_LIST =  "/api/agency/flightCase/byBooking?"; //rest FlightCaseController.casesByBooking
const BOOKING_API_URL = "/api/booking?" 
const GET_BOOKINGLOGS_URL = "/api/agency/getBookingLogs?" //rest ReservationController.getBookingLogs
const GET_BOOKING_URL = "/api/app/getBooking?"; //rest AppApiController.getBooking
const BOOKING_RETRIEVE_URL = "/api/booking/retrieve?" //rest BookingApiController.createReservation
const GET_BOOKINGList_URL =  "/api/app/bookingList?" //rest AppApiController.bookingsJson
const SEARCH_BOOKING_URL = "/api/booking/find?filekey="; //rest BookingApiController.findBooking
const GET_PAYMENT_METHODS = "/api/payment/method"; // gets payment methods as threed
const POST_PAYMENT_ISSUE  = "/api/booking/issue?"; // posts payment selection with params

//SSR ENDPOINTS
const GET_SEAT_MAP_URL =  "/api/flights/getSeatMap?"; //rest FlightSsrController.getSeatMap
const CONFIRM_SEAT_SSR_URL =  "/api/flights/confirmSeatSelection?" //rest FlightSsrController.confirmSeatSelection
const CONFIRM_MEAL_SELECTION_URL = "/api/flights/confirmMealSelection?" //rest FlightSsrController.confirmMealSelection
const GET_MEAL_SSR_URL =  "/api/flights/getMealSsr?" //rest FlightSsrController.getMealSsr
const GET_BAGGAGE_SSR_URL = "/api/flights/getBaggageSsr?" //rest FlightSsrController.getBaggageSsr
const CONFIRM_BAGGAGE_SSR_URL =  "/api/flights/confirmBaggageSelection?" //rest FlightSsrController.confirmBaggageSelection
const CONFIRM_CHECKIN_SSR_URL =  "/api/flights/confirmAirportCheckinSelection?" //rest FlightSsrController.confirmAirportCheckinSelection
const GET_CHECKIN_SSR_URL =  "/api/flights/getAirportCheckinSsr?" //rest FlightSsrController.getAirportCheckinSsr
const CONFIRM_WHEELCHAIR_SSR_URL =  "/api/flights/confirmWheelChairSelection?" //rest FlightSsrController.confirmWheelChairSelection
const GET_WHEELCHAIR_SSR_URL =  "/api/flights/getWheelChairSsr?" //rest FlightSsrController.getWheelChairSsr
const POST_UPDATE_WCHR_MANUAL = "/api/flights/updateWheelChairSsr?" //updates manual wchr request status and can send remarks

//AGENT SALE INVOICE REPORTING
const AGENT_INVOICE_LIST =  "/invoices/list?"; //rest InvoiceController.getAgentInvoiceList
const AGENT_INVOICE_PDF =  "/invoices/{invoice#}.pdf?"; //rest InvoiceController.getInvoicePdf
const AGENT_SALESREPORT =  "/agentsalereport?" //rest InvoiceController.getAgentSalereport
const AGENT_TRANSACTION =  "/agentTransactions?" //rest InvoiceController.getAgentTransactions

// PAXCONNECT
const PAXCONNECT_URL =  "/agency/flightSearch/paxconnect?" //returns url: PaxconnectController.saveFlightSearchOffer
const PAXCONNECT_HOTEL_URL =  "/agency/hotel/paxconnect?" //returns url: PaxconnectController.saveHotelOffer
const PAXCONNECT_RAC_URL =  "/agency/rentacar/paxconnect?" //returns url: PaxconnectController.saveRentACarOffer
const PAXCONNECT_BOOKING_URL = "/agency/viewBooking/" //returns url: PaxconnectController.paxconnect


//FLIGHTPLAN
const FLIGHTPLAN_URL = getHost() + "/agency/flightSearch/offerPdf?"

//FLIGHT
const FLIGHT_BEST_PRICES_URL =  "/api/flight/best-prices?"; //rest .FlightApiController.bestPrices
const BRAND_OFFER_URL = "/api/flight/brand-offers?"; //rest FlightApiController.getBrandedOffers
const FLIGHT_START_SEARCH_URL =  "/api/flight/search/start?"; //rest FlightApiController.startSearch
const FLIGHT_SEARCH_URL =  "/api/flight/search/results?"; //rest FlightApiController.results
const FLIGHT_OFFER_DETAILS_URL =  "/api/flight/search/offer?"; //rest FlightApiController.getOffer
const FLIGHT_OFFER_ANCILLARY_SERVICES_URL =  "/api/flight/search/ancillary-services?"; //yok?
const SEND_RESERVATION_FLIGHT_URL = "/api/flight/reserve?" //rest FlightApiController.createReservation
const ROUND_TRIP_CHECK_URL ="/api/flight/check-roundtrip-price?"; //rest FlightApiController.checkRoundtripPrice
const AIRPORT_AUTOCOMPLETE_URL = "/api/flight/airports?"; //rest AutocompleteController.getAirportsByKeyword
const BESTPRICES_BYDATES_URL ="/api/flight/best-price-by-dates?"; //
const REISSUESEARCH = "/api/flight/reissue/search?"
const REISSUEVALIDATE = "api/flight/reissue/validate?"
const REIISUECONFIRM = "api/flight/reissue/confirm?"

//RENT A CAR
const RENTAL_LOCATION_AUTOCOMPLETE_URL =  "/api/rentalcar/search/locations?"; //rest RentalcarApiController.getAirportsByKeyword
const RENTAL_CAR_START_SEARCH_URL =  "/api/rentalcar/search/start?"; //rest RentalcarApiController.startSearch
const RENTAL_CAR_RESERVE =  "/api/rentalcar/reserve?"; //rest RentalcarApiController.createReservation
const RENT_CAR_OFFERS_SEARCH_URL =  "/api/rentalcar/search/results?"; //rest RentalcarApiController.results
const RENT_A_CAR_QUERY_EXTRAS = "/api/rentalcar/queryextras"

//HOTEL
const HOTEL_SEARCH_AUTOCOMPLETE_URL =  "/api/hotel/search-autocomplete?"; //rest HotelApiController.searchAutocomplete
const HOTEL_DETAILS_URL =  "/api/hotel/details?"; //rest HotelApiController.hotelDetails
const OFFER_DETAILS_URL =  "/api/hotel/search/offer-details?"; //rest HotelApiController.offerDetails
const HOTEL_START_SEARCH_URL =  "/api/hotel/search/start?"; //rest HotelApiController.startSearch
const HOTEL_START_SEARCH_OFFERS_URL =  "/api/hotel/search/all-offers/start?"; //rest HotelApiController.startAllOffersSearch
const HOTEL_SEARCH_URL =  "/api/hotel/search/results?"; //rest HotelApiController.results
const OFFERS_SEARCH_URL =  "/api/hotel/search/all-offers/results?"; //rest HotelApiController.allOffersResults
const SEND_RESERVATION_URL = "/api/hotel/reserve?"; //rest HotelApiController.reserve
const HOTEL_AVAILABILITY_URL =  "/api/hotel/search/room-availabilities?"; //rest HotelApiController.roomAvailabilities

//TOUR
const TOUR_SEARCH_AUTOCOMPLETE_URL = "/api/tour/search-autocomplete?"; //rest TourApiController.searchAutocomplete
const TOUR_SEARCH_SUGGESTET_URL =  "/api/tour/search-autocomplete"; //rest TourApiController.searchAutocomplete
const TOUR_SEARCH_URL =  "/api/tour/search?"; //rest TourApiController.search
const TOUR_OFFER_DETAILS =  "/api/tour/details?" //rest TourApiController.details
const SEND_TOUR_RESERVATION_URL =  "/api/tour/reserve?"; //rest TourApiController.createTransferReservation

//TRANSFER
const TRANSFER_SEARCH =  "/api/transferSearchv3?" //rest TransferApiController.transferSearchV3
const PICKUP_LOCATIONS =  "/api/transferSearch/getAllPickupLocations?" //rest TransferApiController.getAllPickupLocations
const DESTINATION_LOCATIONS = "/api/transferSearch/getDestinationsForPickupLocation?" //rest TransferApiController.getDestinationsForPickupLocation
const TRANSFER_RESERVE = "/api/transfersearch/createtransferreservation?" //rest TransferApiController.createTransferReservation

//HOMEPAGE
const GET_ADVERTISEMENTBANNER_URL =  "/api/misc/advertisementBanners?" //rest MiscApiController.listAdvertisementBanners
const GET_FLIGHTBANNER_URL = "/api/banner/flight"
const GET_RENTALCARBANNER_URL = "/api/banner/rentalcar"
const GET_HOTELBANNER_URL = "/api/banner/hotel"

//PACKAGETOUR
const PACKAGETOUR_AUTOCOMPLETE_URL = "/api/tourpackage/autocomplete?"; //rest TourPackageController.autocomplete
const PACKAGETOUR_SEARCH_URL =  "/api/tourpackage/offers?" //rest TourPackageController.getOffers
const PACKAGETOUR_HOTELOFFER_URL = "/api/tourpackage/offers/hotel?" //rest TourPackageController.getOffersByHotel
const PACKAGETOUR_CHECKPRICES_URL ="/api/tourpackage/offers/checkPrice?" //rest TourPackageController.checkOfferPrice
const PACKAGETOUR_DETAIL_URL =  "/api/tourpackage/offers/detail?" //rest TourPackageController.getOfferDetail
const PACKAGETOUR_BOOK_URL =  "/api/tourpackage/book" //rest TourPackageController.book
const PACKAGETOUR_ALTERNATIVEFLIGHTS_URL =  "/api/tourpackage/offers/transport?" //rest TourPackageController.getOffersForAlternativeTransport

//BOOKING ACTIONS
const DELETE_PNR = "/agency/deletePnr?"
const CANCEL_PNR = "/agency/cancelPnr?"

//BOOKING APPROVALS
const BOOKINGAPPROVALS = "/api/booking-approval?"

//ACCOUNTING
const WALLETLIST = "/api/app/walletList?"

//END API URLS
export const bookingApproval = (approved:boolean): string => {
  return buildUrl(BOOKINGAPPROVALS,{approved}) 
}

export const getRentaCarExtras = () : string => {
  return RENT_A_CAR_QUERY_EXTRAS
}

export const getPaymentMethods = () : string => {
  return GET_PAYMENT_METHODS
}
export const getCheckLoggedInLink = (): string => {
  return CHECK_LOGGED_IN
}
export const postBookingIssuePayment = (): string => {
  return buildUrl(POST_PAYMENT_ISSUE,{})
}
export const getConfigUrl = (): string => {
  return CONFIG_URL
}
export const getAdvertisement = (): string => {
  return buildUrl(GET_ADVERTISEMENTBANNER_URL, {});
};
export const getFlightBanner = (): string => {
  return buildUrl(GET_FLIGHTBANNER_URL, {});
};
export const getRentalcarBanner = (): string => {
  return buildUrl(GET_RENTALCARBANNER_URL, {});
}
;export const getHotelBanner = (): string => {
  return buildUrl(GET_HOTELBANNER_URL, {});
};
export const getBookingLogs = (): string => {
  return buildUrl(GET_BOOKINGLOGS_URL, {});
};
export const getApproachingFlights = (): string => {
  return buildUrl(APPROACHINGFLIGHTS_URL, {});
};
export const postValidateRefundUrl = (): string => {
  return buildUrl(VALIDATE_REFUND, {});
};
export const postAgenyRefundUrl = (): string => {
  return buildUrl(AGENCY_REFUND, {});
};
export const getViewBookingUrl = (bookingKey: string): string => {
  return buildUrl(VIEWBOOKINGPDF_URL + bookingKey , {});
};
export const getBestPricesByDates = (from: string, to: string, dateList: string[]): string => {
  return buildUrl(BESTPRICES_BYDATES_URL, { from: from, to: to, dateList });
};

export const getBookingListUrl = (displayStart: string,
                                    displayLength: string,
                                    search: string,
                                    filterStatus: string,
                                    filterCars: string,
                                    filterFlights: string,
                                    filterHotels: string,
                                    filterTransfers: string
): string => {
  return buildUrl(GET_BOOKINGList_URL, {
    displayStart: displayStart,
    displayLength: displayLength,
    search: search,
    filterStatus: filterStatus,
    filterCars: filterCars,
    filterFlights: filterFlights,
    filterHotels: filterHotels,
    filterTransfers: filterTransfers});
};
export const getWalletList = (
  displayStart: string,
  displayLength: string,
  search: string,
  agencyNumber: string,
 
): string => {
return buildUrl(WALLETLIST, {
displayStart: displayStart,
displayLength: displayLength,
search: search,
agencyNumber: agencyNumber
})

};
export const changePnrStatusURL = (bookingKey: string, pnrId: number, status: string): string => {
  return buildUrl(CHANGE_PNR_STATUS_URL, { bookingKey: bookingKey, pnrId: pnrId, status: status});
}
export const addFlightSegment = (bookingKey: string, pnrId: number): string => {
  return buildUrl(ADD_SEGMENT, { bookingKey: bookingKey, pnrId: pnrId});
}
export const refreshPnrURL = (fileKey: string): string => {
  return buildUrl(AGENCY_REFRESH_PNR, { filekey: fileKey});
}
export const postCancellationFeeHotel = (): string => {
  return buildUrl(GET_CANCELLATION_FEE, {});
};
export const confirmSeatSelection = (): string => {
  return buildUrl(CONFIRM_SEAT_SSR_URL, {});
};
export const getWheelChairSsr = (): string => {
  return buildUrl(GET_WHEELCHAIR_SSR_URL, {});
};
export const confirmWheelChairSelection = (): string => {
  return buildUrl(CONFIRM_WHEELCHAIR_SSR_URL, {});
};
export const getAirportCheckinSsr = (): string => {
  return buildUrl(GET_CHECKIN_SSR_URL, {});
};
export const confirmAirportCheckinSelection = (): string => {
  return buildUrl(CONFIRM_CHECKIN_SSR_URL, {});
};
export const confirmBaggageSelection = (): string => {
  return buildUrl(CONFIRM_BAGGAGE_SSR_URL, {});
};
export const getBaggageSsr = (): string => {
  return buildUrl(GET_BAGGAGE_SSR_URL, {});
};
export const getMealSsr = (): string => {
  return buildUrl(GET_MEAL_SSR_URL, {});
};
export const confirmMealSelection = (): string => {
  return buildUrl(CONFIRM_MEAL_SELECTION_URL, {});
};
export const getIssueBookingUrl = (): string => {
  return ISSUE_BOOKING_URL;
};
export const getIssueBookingWithStripeUrl = (): string => {
  return ISSUE_BOOKING_WITH_STRIPE_URL;
};

export const getSeatmapUrl = (): string => {
  return buildUrl(GET_SEAT_MAP_URL, {});
};

export const getLogoutUrl = (): string => {
  return LOGOUT;
};

export const getAgencyAdminManageUserUrl = (): string => {
  return AGENCY_ADMIN_MANAGE_USER;
};

export const getAgencyInsuranceUrl = (): string => {
  return AGENCY_INSURANCE;
};

export const getAgencyAdminSettingsUrl = (): string => {
  return AGENCY_ADMIN_SETTINGS;
};

export const getNotificationUrl = (): string => {
  return NOTIFICATION_URL;
};

export const getUserDataUrl = (): string => {
  return USER_DATA_URL;
};

export const getResolveNotificationCaseUrl = (): string => {
  return RESOLVE_NOTIFICATION_CASE_URL;
};

export const getNotificationSaveMsgUrl = (): string => {
  return NOTIFICATION_SAVE_MSG_URL;
};

export const getUserMetaDataUrl = (): string => {
  return USER_META_DATA_URL;
};

export const getSearchBookingUrl = (bookingKey: string): string => {
  return SEARCH_BOOKING_URL + bookingKey;
};

export const getAgencyExpiTransactionUrl = (term: string): string => {
  return AGENCY_EXPI_TRANSACTION + term;
};

export const getAgencyPrePaymentWalletUrl = (term: string): string => {
  return AGENCY_PRE_PAYMENT_WALLET + term;
};

export const getHotelSearchAutocompleteUrl = (term: string): string => {
  return buildUrl(HOTEL_SEARCH_AUTOCOMPLETE_URL, { term });
};

export const getTourSearchAutocompleteUrl = (term: string): string => {
  return buildUrl(TOUR_SEARCH_AUTOCOMPLETE_URL, { term });
};

export const getTourSearchSuggestedUrl = (): string => {
  return TOUR_SEARCH_SUGGESTET_URL;
}

export const getAirportAutocompleteUrl = (term: string): string => {
  return buildUrl(AIRPORT_AUTOCOMPLETE_URL, { term });
};

export const getRentalLocationAutocompleteUrl = (term: string): string => {
  return buildUrl(RENTAL_LOCATION_AUTOCOMPLETE_URL, { term });
};

export const getPickupLocationAutocompleteUrl = (): string => {
  return buildUrl(PICKUP_LOCATIONS, { src: "B2C" });
};

export const getDestinationLocationAutocompleteUrl = (pickupLocationId: any): string => {
  return buildUrl(DESTINATION_LOCATIONS, { src: "B2C", pickupLocationId });
};

export const getHotelDetailsUrl = (hotelId: string, locale:string): string => {
  return buildUrl(HOTEL_DETAILS_URL, { hotelId, locale });
};

export const getHotelStartSearchUrl = (): string => {
  return buildUrl(HOTEL_START_SEARCH_URL, {});
};

export const getRentCarReservehUrl = (): string => {
  return buildUrl(RENTAL_CAR_RESERVE, { src: 'B2C' });
};

export const getTransferReservehUrl = (data: any): string => {
  return buildUrl(TRANSFER_RESERVE, { src: 'B2C', ...data });
};

export const getTransferSearchUrl = (data: any): string => {
  return buildUrl(TRANSFER_SEARCH, { src: 'B2C', ...data });
};

export const getRentCarStartSearchUrl = (): string => {
  return buildUrl(RENTAL_CAR_START_SEARCH_URL, { src: 'B2C' });
};

export const getRentCarOffersSearchUrl = (searchId: string): string => {
  return buildUrl(RENT_CAR_OFFERS_SEARCH_URL, { searchId });
};

export const getHotelSearchUrl = (searchId: string): string => {
  return buildUrl(HOTEL_SEARCH_URL, { searchId });
};

export const getTourSearchResultsUrl = (): string => {
  return buildUrl(TOUR_SEARCH_URL, {});
};

export const getTourOfferDetailsUrl = (offerId: string): string => {
  return buildUrl(TOUR_OFFER_DETAILS, { offerId });
};

export const getFlightStartSearchUrl = (): string => {
  return buildUrl(FLIGHT_START_SEARCH_URL, {});
};

export const getFlightSearchUrl = (searchId: string): string => {
  return buildUrl(FLIGHT_SEARCH_URL, { searchId });
};
export const getBrandOfferUrl = (): string => {
  return buildUrl(BRAND_OFFER_URL, {});
}
export const getPackageTourOfferUrl = (): string => {
  return buildUrl(PACKAGETOUR_SEARCH_URL, {});
}
export const getPackaTourHotelOfferUrl = (): string => {
  return buildUrl(PACKAGETOUR_HOTELOFFER_URL, {});
}
export const getPackageTourCheckPrices = (): string => {
  return buildUrl(PACKAGETOUR_CHECKPRICES_URL, {});
}
export const getPackageTourDetail = (): string => {
  return buildUrl(PACKAGETOUR_DETAIL_URL, {});
}
export const getPackageTourBook = (): string => {
  return buildUrl(PACKAGETOUR_BOOK_URL, {});
}
export const getPackageTourAlternativeFlights = (): string => {
  return buildUrl(PACKAGETOUR_ALTERNATIVEFLIGHTS_URL, {});
}
export const getPaxConnect = (): string => {
  return buildUrl(PAXCONNECT_URL, {});
}
export const getPaxConnectHotel = (): string => {
  return buildUrl(PAXCONNECT_HOTEL_URL, {});
}
export const getPaxConnectRentACar = (): string => {
  return buildUrl(PAXCONNECT_RAC_URL, {});
}
export const getPaxConnectBooking = (): string => {
  return buildUrl(PAXCONNECT_BOOKING_URL, {});
}
export const getFlightPlan = (recos: string): string => {
  return buildUrl(FLIGHTPLAN_URL, {
    recos: recos
  });
}
export const getFlightOfferDetailsUrl = (offerId: string): string => {
  return buildUrl(FLIGHT_OFFER_DETAILS_URL, { offerId });
};

export const getFlightOfferAncillaryServicesUrl = (offerId: string): string => {
  return buildUrl(FLIGHT_OFFER_ANCILLARY_SERVICES_URL, { offerId });
};

export const getOffersStartSearchUrl = (): string => {
  return buildUrl(HOTEL_START_SEARCH_OFFERS_URL, {});
};

export const getOffersSearchUrl = (searchId: string): string => {
  return buildUrl(OFFERS_SEARCH_URL, { searchId });
};

export const getOfferDetailsUrl = (offerId: string): string => {
  return buildUrl(OFFER_DETAILS_URL, { offerId });
};

export const getSendReservationUrl = (): string => {
  return buildUrl(SEND_RESERVATION_URL, {});
};
export const getSendReservationFlightUrl = (): string => {
  return buildUrl(SEND_RESERVATION_FLIGHT_URL, {});
};

export const getSendTourReservationUrl = (): string => {
  return buildUrl(SEND_TOUR_RESERVATION_URL, {});
};

export const getRoomAvailabilityUrl = (hotelId: string): string => {
  return buildUrl(HOTEL_AVAILABILITY_URL, { hotelId });
};

export const getJupiterViewBookingUrl = (bookingKey: string) => {
  return buildUrl(JUPITER_VIEW_BOOKING + bookingKey + "?", {});
}

export const getGetBookingUrl = (bookingKey: string) => {
  return buildUrl(GET_BOOKING_URL, { bookingKey });
}
export const getBookingApiUrl = (bookingKey: string) => {
  return buildUrl(BOOKING_API_URL, { bookingKey });
}
export const bookingRetrieveUrl = (bookingKey: string, email: string) => {
  return buildUrl(BOOKING_RETRIEVE_URL, { bookingKey, email });
};

export const getAgentInvoiceListUrl = () => {
  return buildUrl(AGENT_INVOICE_LIST, {});
}

export const getAgentInvoicePdfUrl = (invoiceNumber: string) => {
  return buildUrl(AGENT_INVOICE_PDF.replace("{invoice#}", invoiceNumber), {});
}

export const getFlightCaseUrl = (bookingKey: string) => {
  return buildUrl(FLIGHT_CASE_LIST, { bookingKey });
}

export const getAddFlightCaseUrl = () => {
  return ADD_CASE_LIST;
}
export const postSaveRemarksUrl = () => {
  return SAVE_REMARKS;
}
export const postsaveInternalRemarks = () => {
  return SAVE_INTERNALREMARKS;
}
export const postRescanPnr = () => {
  return RESCANPNR;
}
export const postRescanPnrtakeReservation = () => {
  return RESCANPNR_TAKERESERVATION;
}
export const postReissueSearch = () => {
  return REISSUESEARCH;
}
export const postReissueValidate = () => {
  return REISSUEVALIDATE;
}

export const getBestPricesUrl = (from: string, to: string, startDate: string, endDate: string) => {
  return buildUrl(FLIGHT_BEST_PRICES_URL, { from, to, startDate, endDate })
}
export const postReissueConfirm = () => {
  return REIISUECONFIRM;
}
export const getRoundtripCheckUrl = (
    offerId: string,
    withBaggage: boolean
): string => {
  return buildUrl(ROUND_TRIP_CHECK_URL, {
    offerId,
    withBaggage: String(withBaggage)
  });
};
export const getPackageTour_Autocomplete_url = (term: string): string => {
  return buildUrl(PACKAGETOUR_AUTOCOMPLETE_URL, { "keyword":term });
};

export const getAgentSalesreportURL = (partnerCode: string, dateStart: string, dateEnd: string, returnObject?: boolean) => {
  const params: { partnerCode: string, beginDate: string, endDate: string, returnObject?: boolean } = {
      partnerCode: partnerCode,
      beginDate: dateStart,
      endDate: dateEnd
  };
  
  if (returnObject !== undefined) {
      params.returnObject = returnObject;
  }

  return buildUrl(AGENT_SALESREPORT, params);
}


export const getAgentTransactionURL = () => {
  return buildUrl(AGENT_TRANSACTION, {})
}

export const getDeletePnrURL = (filekey: string, bookingKey: string) => {
  return buildUrl(DELETE_PNR, {filekey, bookingKey})
}

export const getCancelPnrURL = (filekey: string, bookingKey: string) => {
  return buildUrl(CANCEL_PNR, {filekey, bookingKey})
}
export const postUpdateWCHR = (ssrRecordId:string , manualStatus:string, manualRemarks: string) => {
  return buildUrl(POST_UPDATE_WCHR_MANUAL, manualRemarks.length > 0 
    ? {ssrRecordId: ssrRecordId, manualStatus:manualStatus, manualRemarks: manualRemarks}
    : {ssrRecordId: ssrRecordId, manualStatus:manualStatus})
}
export const buildUrl = (url: string, { ...params }): string => {
  const urlParams = isB2B() ? new URLSearchParams({ ...params }) : new URLSearchParams( params );
  return url + urlParams.toString();
}

export function getHost() {
  if (
      window.location.origin.includes(":8080") ||
      window.location.origin.includes("185.3.232.199") ||
      window.location.origin.includes("elasticbeanstalk.com")
  )
    return window.location.origin;

  if (window.location.origin.includes(":3000"))
    return "https://www.atrtouristik.com";

  if (window.location.origin.includes("cloud.atrtouristik.com"))
    return "https://cloud.atrtouristik.com";

  return "https://www.atrtouristik.com";
}
